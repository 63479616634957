<template>
    <v-container class="recipe">
        <v-row v-if="busy">
            <v-col>
                <v-skeleton-loader type="image, article"/>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-img
                class="mb-5"
                src="/app/dist/img/default.png"
                lazy-src="/app/dist/img/default.png"
                height="400"
                max-height="400">
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"/>
                    </v-row>
                </template>
            </v-img>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="7" lg="8" xl="9">
                        <h1 class="text-h3 mb-1 font-weight-black">{{ recipe.title }}</h1>
                        <h2 class="text-h5 mb-5 font-weight-medium">{{ recipe.subtitle }}</h2>
                        <p
                            v-if="recipe.description"
                            class="mb-5 me-5 pe-5"
                            v-html="recipe.description">
                        </p>
                        <v-divider class="mb-3"/>
                        <ul class="list-inline mb-3">
                            <li class="d-flex me-3">
                                <v-icon left color="primary">mdi-silverware-variant</v-icon>
                                <strong>Servings:</strong>&nbsp;<span>{{ recipe.servings }}</span>
                            </li>
                            <li class="d-flex me-3">
                                <v-icon left color="primary">mdi-clock-outline</v-icon>
                                <strong>Prep. Time:</strong>&nbsp;<span>{{ $duration(recipe.prepTime) }}</span>
                            </li>
                            <li class="d-flex me-3">
                                <v-icon left color="primary">mdi-clock-fast</v-icon>
                                <strong>Cook Time:</strong>&nbsp;<span>{{ $duration(recipe.cookTime) }}</span>
                            </li>
                            <li class="d-flex me-3">
                                <v-icon left color="primary">mdi-silverware-clean</v-icon>
                                <strong>Difficulty:</strong>&nbsp;<span class="text-capitalize">{{ recipe.difficulty }}</span>
                            </li>
                        </ul>
                        <v-divider class="mb-5"/>
                        <v-chip
                            v-for="tag, i in recipe.tags"
                            :key="i"
                            class="me-2 mb-5 font-weight-medium">
                            {{ tag.title }}
                        </v-chip>
                        <h3 class="text-h5 mb-3 d-flex">
                            <v-icon class="me-1" color="primary">mdi-format-list-numbered</v-icon>
                            <strong>Instructions:</strong>
                        </h3>
                        <ol class="mb-5 pb-5 recipe__instructions">
                            <li
                                class="me-5 pe-5"
                                v-for="instruction, i in recipe.instructions"
                                :key="i">
                                <h4 class="text-h6">{{ instruction.step }}</h4>
                                <p v-for="line, i in instruction.instruction" :key="i" v-html="line"></p>
                            </li>
                        </ol>
                    </v-col>
                    <v-col cols="12" md="5" lg="4" xl="3">
                        <div class="recipe__ingredients">
                            <h3 class="text-h5 mb-3 d-flex">
                                <v-icon class="me-1" color="primary">mdi-nutrition</v-icon>
                                <strong>Ingredients:</strong>
                            </h3>
                            <ul class="list-unstyled">
                                <li
                                    class="mb-2"
                                    v-for="ingredient, i in recipe.ingredients"
                                    :key="i">
                                    {{ $fraction(ingredient.amount) }} {{ ingredient.unit }} <strong>{{ ingredient.ingredient }}</strong>
                                </li>
                            </ul>
                            <v-btn
                                block
                                large
                                class="mt-6 body-1"
                                color="primary"
                                :disabled="addedToCart"
                                @click.prevent="addToShoppingCart">
                                <v-icon left>mdi-cart-arrow-down</v-icon>
                                <span>Add to Cart</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-divider class="mb-3"/>
                <ul class="list-inline justify-md-end">
                    <li class="me-3" v-if="recipe.author">
                        <strong>Author:</strong>
                        &nbsp;{{recipe.author.fullName}}
                    </li>
                    <li class="me-3">
                        <strong>Created:</strong>
                        &nbsp;{{ $moment(recipe.dateCreated).format('LL') }}
                    </li>
                    <li class="me-3">
                        <strong>Updated:</strong>
                        &nbsp;{{ $moment(recipe.dateUpdated).fromNow() }}
                    </li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Recipe',
    data: () => ({
        busy: false,
        recipe: {}
    }),
    computed: {
        addedToCart () {
            if (this.$store.getters.addedToCart(this.recipe.slug)) {
                return true
            }
            return false
        }
    },
    methods: {
        async fetchRecipe () {
            this.busy = true
            const { entries } = await this.$api(`{
                entries (section: "recipes", slug: "${this.$route.params.slug}") {
                    id
                    slug
                    title
                    author {
                        username
                        fullName
                    }
                    dateCreated
                    dateUpdated
                    ... on recipes_recipe_Entry {
                        subtitle
                        description
                        difficulty
                        cookTime
                        prepTime
                        servings
                        ingredients {
                            ingredient
                            amount
                            unit
                        }
                        instructions {
                            step
                            instruction
                        }
                        tags {
                            id
                            title
                        }
                        metaTitle
                        metaDescription
                    }
                }
            }`)
            if (entries) {
                this.recipe = entries[0]
                const ingredients = this.recipe.ingredients.map(item => item.ingredient)
                this.recipe.instructions.forEach((instruction, i) => {
                    ingredients.forEach(item => {
                        const regex = new RegExp(item, 'gi')
                        instruction.instruction = instruction.instruction.replace(regex, `<strong>${item}</strong>`)
                    })
                    this.recipe.instructions[i].instruction = instruction.instruction.split('\n\n')
                })
                setTimeout(() => {
                    this.busy = false
                }, 250)
            }
        },
        addToShoppingCart () {
            this.$store.dispatch('addToShoppingCart', this.recipe)
        }
    },
    mounted () {
        this.fetchRecipe()
    }
}
</script>